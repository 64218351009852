import { platform } from "./support/platform";

export const env = `${import.meta.env.ENV ?? ""}`;

export const logging = !!import.meta.env.LOGGING;

export const version = `${import.meta.env.VERSION ?? ""}`;

export const mapboxToken =
  "pk.eyJ1IjoiZ3JhaGFtZ2liYm9uc2tyYXVzIiwiYSI6ImNsOWhjcXl4dDEyNWwzb295MjZhdWh6ejkifQ.1o2-p9zy03ahonJJD1SSow";

export const googleClientId =
  "143826992885-d13e9fsl4hnb07sqk2ichcf2ilt6ntkc.apps.googleusercontent.com";

export const newRelicApiKey = "fd0c9de957169bfadf7ceb2849bf3ff4FFFFNRAL";

export const rollbarToken = "27305fbc9b4448b1875d0c65401ccebf";

export const websocketEndpoints = `${import.meta.env.WEBSOCKET_ENDPOINTS ?? "ws://localhost:8080"}`;

export const udpPorts = platform === "web" ? [] : [14550];

export const interval = 250;

export const elevationTileUrl = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${mapboxToken}`;

export const baseMapUrls = {
  ["Google"]: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  ["Google Hybrid"]: "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
  ["Mapbox"]: `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.jpg80?access_token=${mapboxToken}`,
  ["Sectional Chart"]:
    "https://geoint.nrlssc.navy.mil/nrltileserver/wms/family/GeoImage?bbox={bbox}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=FAASectionals"
};

export const gcsSystemId = 255;
